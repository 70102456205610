header {
	transition: all 5s ease;

	.navbar {
		height: 123px;

		@include media-breakpoint-down(md) {
			height: 100%;
		}

		.navbar-brand {
			margin: auto auto auto 8.33333%;

			@include media-breakpoint-down(lg) { margin: auto; }

			@include media-breakpoint-down(md) { margin: 0; }
		}

		.navbar-toggler {
			border-color: $main-opacity-01;

			&:focus {
				outline: solid 1px $main-opacity-05;
			}
		}

		.navbar-nav {
			margin-right: 8.33333%;

			@include media-breakpoint-down(lg) {
				margin-right: 0;
			}

			.nav-item {

				.nav-link {
					color: $nav-link;
					font-size: 14px;
					line-height: 14px;
					padding-left: 2rem;
					padding-right: 2rem;
					text-transform: uppercase;

					@include media-breakpoint-down(md) {
						text-align: center;
					}

					&:hover {
						color: $main-color;
						font-family: $font-semibold;
					}
				}
			}
		}
	}
}
