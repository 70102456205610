html {
	overflow-x: hidden;
}

body {
	overflow-x: hidden;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 { color: $main-color; }

	h1 { font-family: $font-black; font-size: 71px;	line-height: 75px;

		@include media-breakpoint-down(lg) { font-size: 61px;	line-height: 65px; }

		@include media-breakpoint-down(md) { font-size: 51px;	line-height: 55px; }

		@include media-breakpoint-down(sm) { font-size: 41px;	line-height: 45px; }
	}

	h2 { font-family: $font-black; font-size: 55px;	line-height: 59px;

		@include media-breakpoint-down(lg) { font-size: 61px;	line-height: 65px; }

		@include media-breakpoint-down(md) { font-size: 51px;	line-height: 55px; }

		@include media-breakpoint-down(sm) { font-size: 41px;	line-height: 45px; }
	}

	h3 { font-family: $font-medium; font-size: 45px;	line-height: 49px;

		@include media-breakpoint-down(md) { font-size: 40px;	line-height: 44px; }
	}

	h4 { font-family: $font-medium; font-size: 40px;	line-height: 44px;

		@include media-breakpoint-down(lg) { font-size: 35px; line-height: 39px; }

		@include media-breakpoint-down(md) { font-size: 30px; line-height: 34px; }

		@include media-breakpoint-down(sm) { font-size: 23px; line-height: 27px; }
	}

	h5 { font-family: $font-medium; font-size: 25px;	line-height: 29px;

		@include media-breakpoint-down(md) { font-size: 20px; line-height: 24px; }
	}

	h6 { font-family: $font-extrabold; font-size: 20px;	line-height: 24px;

		@include media-breakpoint-down(md) { font-size: 18px; line-height: 22px; }
	}

	p,
	a {	color: $paragraph; font-family: $font-light; font-size: 19px;	line-height: 23px;

		@include media-breakpoint-down(lg) { font-size: 18px; line-height: 22px; }

		@include media-breakpoint-down(md) { font-size: 17px; line-height: 21px; }

		@include media-breakpoint-down(sm) { font-size: 16px; line-height: 20px; }
	}

	img {
		height: max-content;
		max-height: 100%;
		max-width: 100%;
		width: max-content;
	}

	.font-black {	font-family: $font-black; }

	.extra-bold {	font-family: $font-extrabold; }

	.semi-bold {	font-family: $font-semibold; }

	.font-medium {	font-family: $font-medium; }

	.main-color { color: $main-color;	}

	.subtitle-txt { font-size: 35px;  line-height: 39px;

		@include media-breakpoint-down(lg) { font-size: 31px; line-height: 35px; }

		@include media-breakpoint-down(md) { font-size: 27px; line-height: 31px; }

		@include media-breakpoint-down(sm) { font-size: 23px; line-height: 27px; }
	}

	.p-txt-large { font-size: 30px;  line-height: 34px;

		@include media-breakpoint-down(lg) { font-size: 26px; line-height: 30px; }

		@include media-breakpoint-down(md) { font-size: 22px; line-height: 26px; }

		@include media-breakpoint-down(sm) { font-size: 18px; line-height: 22px; }
	}

	.p-txt { font-size: 25px;  line-height: 29px;

		@include media-breakpoint-down(lg) { font-size: 22px; line-height: 26px; }

		@include media-breakpoint-down(md) { font-size: 19px; line-height: 23px; }

		@include media-breakpoint-down(sm) { font-size: 16px; line-height: 20px; }
	}

	.card {
		background-color: $main-color;
		border-radius: 26px;
	}

	.btn-main {
		background-color: transparent;
		border: 3px solid $main-color;
		border-radius: 8px;
		color: $main-color;
		font-family: $font-semibold;
		font-size: 25px;
		font-weight: 500;
		min-width: 1em;

		@include media-breakpoint-down(lg) {
			font-size: 22px;
			line-height: 26px;
		}

		@include media-breakpoint-down(md) {
			font-size: 19px;
			line-height: 23px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 16px;
			line-height: 20px;
		}

		&:hover {
			background-color: $main-color;
			color: $white;
		}
	}

	.btn-alt {
		background-color: transparent;
		border: 3px solid $white;
		border-radius: 8px;
		color: $white;
		font-family: $font-medium;
		font-size: 25px;
		min-width: 6em;

		@include media-breakpoint-down(lg) {
			font-size: 22px;
			line-height: 26px;
		}

		@include media-breakpoint-down(md) {
			font-size: 19px;
			line-height: 23px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 16px;
			line-height: 20px;
		}

		&:hover {
			background-color: $white;
			color: $main-color;
		}
	}
}
