@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
}

@media (min-width: 1440px) {
	.container {
		max-width: 1400px;
	}
}
