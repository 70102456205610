main {
	background-color: $white;

	.background-index {
		// background-attachment: fixed;
		background-image: url('img/png/bk-index1.webp');
		background-position: 100% center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 521px;

		@include media-breakpoint-down(md) {
			background-position: 85% center;
		}

		@include media-breakpoint-down(sm) {
			background-position: 90%;
		}

		@include media-breakpoint-down(xs) {
			background-image: url('img/png/bk-index2.webp');
			background-position: bottom;
			background-size: 100%;
		}

		.container {
			height: 100%;

			.row {
				height: 100%;

				div {
					.i-title {
						line-height: 60px;
						margin-bottom: 1.438rem;
						max-width: 84%;

						@media (min-width: 1201px) and (max-width: 1280px) {
							max-width: 100%;
						}
					}

					.i-txt {
						margin-bottom: 0;
						max-width: 90%;

						@media (min-width: 1201px) and (max-width: 1280px) {
							max-width: 96%;
						}

						@include media-breakpoint-down(sm) {
							max-width: 80%;
						}

						@include media-breakpoint-down(xs) {
							max-width: 67%;
						}
					}

					.btn-main {
						margin-top: 1.3em;
					}
				}
			}
		}
	}

	.about-us {
		padding: 3em 0 6.0625em;

		@include media-breakpoint-down(md) {
			padding: 3em 0;
		}

		.cntnt-about-us {
			padding: 0 0 2.625em;

			.col-12.col-md-11.text-center {
				max-width: 93%;

				@media (min-width: 1201px) and (max-width: 1280px) {
					max-width: 89%;
				}

				.about-title {
					font-family: $font-black;
					margin-bottom: 1.4em;
					text-transform: uppercase;
				}

				.about-txt {
					b {
						font-family: $font-medium;
					}
				}
			}
		}

		.cnt-points {
			@include media-breakpoint-down(md) {
				display: flex;
			}

			.dot-box.red-light {
				background-color: $main-color-light;
				border-radius: 50%;
				float: left;
				height: 16px;
				margin-top: 0.4em;
				width: 16px;

				@include media-breakpoint-down(md) {
					margin-left: 1em;
					margin-right: 0.5em;
					margin-top: 0.2em;
				}

				@include media-breakpoint-down(sm) {
					margin-top: 0.1em;
				}
			}

			.dot-text {
				margin-bottom: 0;
			}
		}
	}

	.our-napkins {
		padding: 0 0 2.5em;

		@include media-breakpoint-down(md) {
			padding: 0 0 2em;
		}

		@include media-breakpoint-down(sm) {
			padding: 0 0 1em;
		}

		.decorate-box {
			background-color: $main-color;
			height: 100%;
			padding: 4em 2em;

			@media (min-width: 1280px) and (max-width: 1440px) {
				padding: 2em;
			}

			@media (min-width: 1201px) and (max-width: 1280px) {
				padding: 1em 2em;
			}

			@include media-breakpoint-down(lg) {
				padding: 1em 2em;
			}

			@include media-breakpoint-down(md) {
				padding: 1em 1.2em;
			}

			.decorate-title {
				color: $white;
				font-family: $font-black;
				margin-bottom: 0;
				margin-top: 0.4em;

				@media (min-width: 1201px) and (max-width: 1280px) {
					margin-top: 0.3em;
				}

				@include media-breakpoint-down(md) {
					margin-top: 0;
				}
			}

			ul {
				color: $main-color-light;
				margin-bottom: 2em;
				margin-top: 2em;
				padding-inline-start: 20px;

				@media (min-width: 1201px) and (max-width: 1280px) {
					margin-bottom: 1em;
					margin-top: 1.6em;
				}

				@include media-breakpoint-down(md) {
					margin-bottom: 1em;
					margin-top: 1em;
				}

				@include media-breakpoint-down(sm) {
					margin-bottom: 0.5em;
					margin-top: 0.5em;
				}

				p {
					color: $white;
					margin-bottom: 0.4em;

					@include media-breakpoint-down(md) {
						margin-bottom: 0;
					}
				}
			}

			.decorate-subtxt {
				color: $white;

				@include media-breakpoint-down(md) {
					margin-bottom: 0;
				}
			}
		}

		.img-muestra {
			transition: all 0.3s ease;

			&:hover {
				transform: scale(1.2);

				@include media-breakpoint-down(sm) {
					transform: scale(1.1);
				}
			}

			&.img-up {

				@include media-breakpoint-down(sm) {
					padding-top: 1.9em;
				}
			}

			&.img-down {
				padding-top: 1.9em;

				@include media-breakpoint-down(md) {
					padding-top: 2.8em;
				}

				@include media-breakpoint-down(sm) {
					padding-top: 1.9em;
				}
			}

			img {
				height: 200px;
				width: 170px;

				@include media-breakpoint-down(lg) {
					height: 100%;
					width: 100%;
				}
			}

		}
	}

	.our-products {
		padding: 2.5em 0 7.125em;

		@include media-breakpoint-down(md) {
			padding: 2em 0 5em;
		}

		@include media-breakpoint-down(sm) {
			padding: 0 0 3em;
		}

		.img-prod {
			width: 426px;
		}

		.products-title {
			font-family: $font-black;
			margin-bottom: 0.7em;
			margin-top: 0.6em;
			max-width: 50%;
			text-transform: uppercase;

			@include media-breakpoint-down(lg) {
				margin-top: 0;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 0.35em;
				margin-top: 0.5em;
			}

			@include media-breakpoint-down(sm) {
				margin-bottom: 1em;
				margin-top: 0;
				max-width: 100%;
				text-align: center;
			}
		}

		.p-txt {
			max-width: 75%;

			@media (min-width: 1280px) and (max-width: 1440px) {
				max-width: 85%;
			}

			@media (min-width: 1201px) and (max-width: 1280px) {
				max-width: 94%;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 0.25em;
				max-width: 100%;
			}
		}

		ul {
			color: $main-color;
			font-size: 1.5rem;
			max-width: 80%;
			padding-inline-start: 20px;

			@media (min-width: 1280px) and (max-width: 1440px) {
				max-width: 90%;
			}

			@media (min-width: 1201px) and (max-width: 1280px) {
				max-width: 97%;
			}

			@include media-breakpoint-down(md) {
				max-width: 100%;
			}

			.p-txt {
				margin-bottom: 0.25em;
				max-width: 100%;
			}
		}
	}

	.benefits {
		background-image: url('img/jpg/bk-ventajas.webp');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		// height: 485px;

		@include media-breakpoint-down(lg) {
			height: 100%;
			padding: 2em 0;
		}

		// @include media-breakpoint-down(sm) {
		// 	background-image: url('img/jpg/bk-ventajas-50.webp');
		// }

		.beneftis-title {
			color: $white;
			margin: 0.8em auto 1.3em;

			@media (min-width: 1440px) {
				max-width: 80%;
			}

			@media (min-width: 1281px) and (max-width: 1439px) {
				max-width: 90%;
			}

			@media (min-width: 1201px) and (max-width: 1280px) {
				max-width: 74%;
			}

			span {
				font-family: $font-black;
			}
		}

		img {
			transition: all 0.3s ease-in-out;

			@include media-breakpoint-down(md) {}

			&:hover {
				transform: translateY(-20px);
			}

			&.img-product1 {
				margin-top: 1em;

				@include media-breakpoint-down(sm) {
					width: 50%;
				}
			}

			&.img-product2 {
				@include media-breakpoint-down(sm) {
					width: 32%;
				}
			}

			&.img-product3 {
				margin-top: 0.4em;

				@include media-breakpoint-down(sm) {
					width: 43%;
				}
			}

			&.img-product4 {
				margin-top: 1.1em;

				@include media-breakpoint-down(sm) {
					width: 50%;
				}
			}
		}

		p {
			color: $white;
			margin: 1em auto 0;
			max-width: 69%;

			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}

			span {
				font-family: $font-semibold;
			}
		}

		.beneftis-downtxt {
			color: $white;
			margin-bottom: 1.5em;
			margin-top: 1em;
		}
	}

	.sizes {
		padding: 2.75em 0;

		@include media-breakpoint-down(md) { padding: 3em 0; }

		.bkg-sizes {
			background-image: url('img/png/bk-sizes.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			padding: 4em 0;

			@include media-breakpoint-down(lg) { padding: 3em 0 4.5em; }
		}

		.sizes-title { font-family: $font-bold;	}

		.size-num { font-size: 80px; line-height: 84px;	}

		.size-txt {
			color: $black;
			font-family: $font-medium;

			b { font-family: $font-black; }
		}

		.metering {
			list-style: none;
			margin: 1rem 0;
			padding-inline-start: 0;

			&.grid {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
			}

			li {
				font-size: 0;
				position: relative;

				&::before {
					color: $main-color;
					content: '·';
					font-size: 7rem;
					line-height: 0;
					padding-left: 0;
					position: absolute;
					top: 0.2rem;
					vertical-align: middle;

					@include media-breakpoint-down(md) {
						font-size: 5rem;
						left: 0;
						padding-left: 0.2rem;
					}
				}

				p {
					font-family: $font-medium;
					margin-bottom: 0;
					margin-left: 1.5rem;

					@include media-breakpoint-down(md) {
						margin-left: 1.4rem;
					}

					b { font-family: $font-black; }
				}
			}
		}

		.wrapper-txt {
			@include media-breakpoint-down(md) { display: none; }

			.d-flex {
				align-items: center;
				justify-content: center;

				.size-num { font-size: 80px; line-height: 84px; margin: 0 1rem 0 0;	}

				.size-txt {
					color: $black;
					font-family: $font-medium;
					margin: 0.5rem 1rem 0;
					max-width: 45%;

					@include media-breakpoint-down(md) { max-width: 40%; }

					b { font-family: $font-black; }
				}
			}

			.horizontal {
				border: 2px solid $main-color;
				margin: 1.5rem auto;
				width: 141px;
			}
		}

		.wrapper-img {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			padding: 0;
			text-align: center;

			@include media-breakpoint-down(lg) { grid-column-gap: 1rem;	}

			@include media-breakpoint-down(sm) {
				grid-template-columns: 1fr;
				grid-template-rows: 1fr;
			}

			.lace {
				align-self: center;
				justify-self: center;
				margin-top: 2rem;
				transition: all 0.3s ease-in-out;

				@include media-breakpoint-down(sm) { margin: 1rem 0; }
				@include media-breakpoint-down(xs) { width: 50%; }

				&:hover {	transform: scale(1.1); }
			}
		}

		.row {

			.wrapper-metering {
				display: grid;
				grid-template-columns: 1fr 1fr 2fr 2fr;

				h1,
				p,
				ul {
					align-self: center;
					justify-self: center;
				}

				.p-txt {
					font-family: $font-medium;
					margin: 0 0.5rem;

					b { font-family: $font-black; }
				}

				.metering {

					li {
						&::before {
							padding-left: 0;
						}

						.p-txt {
							margin: 0 0 0 1rem;
						}
					}
				}
			}

			.left {
				left: -2em;

				@include media-breakpoint-down(md) { left: 0; }
			}

			.right {
				right: -2em;

				@include media-breakpoint-down(md) { right: 0; }
			}

			.line {
				border-left: 3px solid $main-color;
				border-top: 0;
				height: 141px;
				// left: calc(50% - 1.5px);
				margin-block-end: 0.5em;
				margin-block-start: 0.5em;
				margin-inline-end: 0;
				margin-inline-start: 0;
				position: absolute;

				@include media-breakpoint-down(md) {height: 14%; }

				&.left {
					left: calc(41% - 1.5px);

					@include media-breakpoint-down(lg) { left: calc(40.5% - 1.5px); }
					@include media-breakpoint-down(md) { left: 48%; }
				}

				&.right {
					right: calc(41% - 1.5px);

					@include media-breakpoint-down(lg) { right: calc(40.5% - 1.5px); }
					@include media-breakpoint-down(md) { right: calc(38.5% - 1.5px); }
					@include media-breakpoint-down(sm) { right: calc(40% - 1.5px); }
					@include media-breakpoint-down(xs) { right: calc(33.5% - 1.5px); }
				}
			}

			.blondas {
				margin-top: 4.5rem;
				transition: all 0.3s ease;

				&:hover {
					transform: scale(1.3);

					@media (max-width: 1279px) { transform: scale(1.15); }
					@include media-breakpoint-down(md) { transform: scale(1.2); }
					@include media-breakpoint-down(sm) { transform: scale(1.1); }
				}
			}
		}

	}

	.know-more {
		padding: 2.75em 0 4.188rem;

		.know-title {
			font-family: $font-medium;
			margin-bottom: 3.375rem;

			b {
				font-family: $font-black;
			}
		}

		.left-txt {
			left: 4em;

			@include media-breakpoint-down(sm) {
				left: 0;
			}
		}

		p {
			font-family: $font-medium;
			max-width: 90%;

			@media (min-width: 1201px) and (max-width: 1280px) {
				max-width: 88%;
			}

			@include media-breakpoint-down(sm) {
				max-width: 100%;
				text-align: center;
			}
		}
	}

	.responsible-use {
		background-image: url('img/jpg/bk-uso-responsable.webp');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		// height: 238px;
		margin-bottom: 4em;
		padding: 1em 0 1.25em;

		@include media-breakpoint-down(lg) {
			height: 100%;
		}

		// @include media-breakpoint-down(sm) {
		// 	background-image: url('img/jpg/bk-uso-responsable-50.webp');
		// }

		.responsible-title {
			color: $white;
			margin: 0 auto 2.188rem;

			@media (min-width: 1440px) {
				max-width: 80%;
			}

			@media (min-width: 1281px) and (max-width: 1439px) {
				max-width: 90%;
			}

			@media (min-width: 1201px) and (max-width: 1280px) {
				max-width: 95%;
			}

			@include media-breakpoint-down(md) {
				max-width: 70%;
			}

			@include media-breakpoint-down(xs) {
				max-width: 66%;
			}

			span {
				font-family: $font-black;
			}
		}

		img {
			transition: all 0.3s ease-in-out;

			&:hover {
				transform: translateY(-20px);
			}

			&.img-product3 {
				margin-top: 0.3em;
			}
		}

		p {
			color: $white;
			font-family: $font-bold;
			margin: 0.3em auto 0;
		}
	}

	.call-us {
		padding: 4em 0;

		@include media-breakpoint-down(md) {
			padding: 2em 0;
		}

		@include media-breakpoint-down(sm) {
			padding: 2em 0 1em;
		}

		.call-title {
			color: $main-color;
			margin: auto;
			// max-width: 90%;

			@media (min-width: 1440px) {
				max-width: 80%;
			}

			@media (min-width: 1281px) and (max-width: 1439px) {
				max-width: 90%;
			}

			span {
				font-family: $font-extrabold;
			}
		}
	}

	.dealers {
		padding: 4em 0;

		@include media-breakpoint-down(md) {
			padding: 2em 0;
		}

		@include media-breakpoint-down(sm) {
			padding: 1em 0 2em;
		}

		.ctnt-down {
			margin-top: 6.5em;

			@include media-breakpoint-down(md) {
				margin-top: 0;
			}
		}

		.dealers-title {
			color: $main-color;
			font-family: $font-black;
			margin-bottom: 0.8em;
			text-transform: uppercase;

			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}

		.dealers-subtitle {
			color: $main-color;
			font-family: $font-light;
			margin-bottom: 1.2em;

			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}

		img {
			margin-right: 0.5em;
		}

		.dealers-dir {
			margin-bottom: 0;

			p {
				margin-bottom: 0;
			}

			span {
				font-family: $font-extrabold;
			}
		}
	}

	.contact {
		background-color: $main-color-contact;
		background-image: url('img/jpg/bk-form.webp');
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: 50% 101%;

		@include media-breakpoint-down(sm) {
			background-image: none;
		}

		.bk-contact {
			// background-color: $main-color-extralight;
			padding-bottom: 0;

			@include media-breakpoint-down(sm) {
				padding-bottom: 3rem;
			}

			.info-contact {
				margin: auto;
				max-width: 70%;

				@media (min-width: 1281px) and (max-width: 1439px) {
					max-width: 90%;
				}

				@media (min-width: 1201px) and (max-width: 1280px) {
					max-width: 89%;
				}

				@include media-breakpoint-down(lg) {
					max-width: 95%;
				}

				@include media-breakpoint-down(md) {
					max-width: 100%;
				}

				.img-title {
					margin-right: 1em;
				}

				.ctnt-title {
					left: -1.5em;
					position: relative;

					@include media-breakpoint-down(sm) {
						left: 0;
					}
				}

				.contact-title {
					font-family: $font-black;
					margin-bottom: 0;
					text-transform: uppercase;
				}

				.img-info {
					margin-right: 0.75em;

					&.marker {
						margin-right: 1.4em;
					}
				}

				a {
					&:hover {
						color: $main-color;
					}

					&.icon-social {
						margin-right: 0.3em;
						outline: none;
						text-decoration: none;
					}
				}
			}
		}

		.bk-form {
			height: 436px;

			@include media-breakpoint-down(sm) {
				background-image: url('img/jpg/bk-form.webp');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 190%;
			}

			@include media-breakpoint-down(xs) {
				background-size: 195%;
			}

			.form-comming-soon {
				margin: auto;
				max-width: 30em;
				padding-top: 0.7em;

				.form-group {
					padding: 18px 0 0;
					position: relative;
					width: 100%;

					input:-internal-autofill-selected {
						background-color: transparent !important;
						color: $yellow !important;
					}
				}

				.form-field {
					background: transparent !important;
					background-color: transparent !important;
					border: 0;
					border-bottom: 2.36px solid $main-color-extralight;
					border-radius: 0;
					color: $white;
					font-family: inherit;
					font-size: 1.1rem;
					outline: 0;
					padding: 7px 0 0;
					transition: border-color 0.2s;
					width: 100%;

					&::placeholder {
						color: transparent;
					}

					&:placeholder-shown ~ .form-label {
						cursor: text;
						font-size: 1.3rem;
						top: 28px;

						@include media-breakpoint-down(sm) {
							font-size: 1rem;
						}
					}

					&:required,
					&:invalid,
					&:focus {
						box-shadow: none;
					}
				}

				.label-txt {
					color: $white;
					font-size: 1.3rem;
					font-weight: 500;
					margin-bottom: 0;

					@include media-breakpoint-down(sm) {
						font-size: 1rem;
					}
				}

				.area-txt {
					background-color: $main-color-extralight;
					border: transparent;
					border-radius: 8px;
					color: $white;
					font-family: $font-semibold;
				}

				.area-txt:focus {
					border-color: $yellow;
					border-image-slice: 1;
					border-width: 3px;
					box-shadow: 0 0 0 0.2rem $area-txt;
				}

				.form-label {
					color: $white;
					display: block;
					font-size: 20px;
					font-weight: 500;
					margin-bottom: 0;
					position: absolute;
					top: 0;
					transition: 0.2s;
				}

				.form-field:focus {
					border-color: $yellow;
					border-image-slice: 1;
					border-width: 3px;
					padding-bottom: 6px;

					~ .form-label {
						color: $yellow;
						display: block;
						font-size: 1rem;
						font-weight: 700;
						position: absolute;
						top: 0;
						transition: 0.2s;
					}
				}
			}
		}
	}
}

.form-note {
	border-radius: 2px;
	bottom: 0;
	color: $white;
	display: block;
	font-size: 16px;
	left: 0;
	line-height: 20px;
	position: fixed;
	text-align: center;
	width: 100%;
	z-index: 1202;
}

.form-confirm {
	background: $form-confirm;
	padding: 18px 0;
	width: 100%;
}

.form-alert {
	background: $form-error;
	padding: 18px 0;
	width: 100%;
}

.field-error {
	border: 1px solid $form-error !important;
}

.floating-wpp {
	bottom: 3em;
	left: auto;
	right: 3em;

	&:hover {
		bottom: 3.1em;
	}

	.floating-wpp-button {
		overflow: unset;

		img {	border-radius: 0;	}
	}

	.floating-wpp-popup {
		&.active {
			right: 0;
			width: 300px;
		}

		.floating-wpp-head {
			align-items: center;

			span {
				font-size: 1.5rem;
			}

			.close {
				color: $white;
				font-size: 1.5rem;
				line-height: 1.3;
				opacity: 1;
				text-shadow: unset;
			}
		}

		.floating-wpp-message {
			font-size: 1rem;
		}

		.floating-wpp-input-message {
			margin-top: 50px;

			// textarea {
			// 	height: 40px;
			// }
		}

		.floating-wpp-btn-send {
			align-items: center;
			background-color: $wp-base;
			border-radius: 20px;
			display: flex;
			height: 35px;
			justify-content: center;
			width: 45px;

			svg {
				path {
					fill: $white;
				}
			}
		}
	}
}

