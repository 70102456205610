@font-face {
	font-family: 'Raleway-light';
	src: url('assets/fonts/Raleway-Light.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway-regular';
	src: url('assets/fonts/Raleway-Regular.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway-medium';
	src: url('assets/fonts/Raleway-Medium.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway-semibold';
	src: url('assets/fonts/Raleway-SemiBold.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway-bold';
	src: url('assets/fonts/Raleway-Bold.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway-extrabold';
	src: url('assets/fonts/Raleway-ExtraBold.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway-black';
	src: url('assets/fonts/Raleway-Black.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}
